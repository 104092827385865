import '@babel/polyfill';

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        "Accept": "application/json",
        "Cache-Control": "no-cache",
        "X-Requested-With": "XMLHttpRequest"
    }
});

$('#show-cart-btn').click(function () {
    window.location.href = "/koszyk";
});

$('.dropdown-toggle').click(function () {
    $('.dropdown-menu').removeClass('dropdown-show');
    $(this).next('.dropdown-menu').addClass('dropdown-show');
});
$(document).click(function (event) {
    if (!$(event.target).closest(".dropdown").length) {
        if ($('.dropdown-menu').is(':visible')) {
            $('.dropdown-menu').removeClass('dropdown-show');
        }
    }
});
const dropdown = $('.dropdown--offer .category_name');
dropdown.mouseover(function (e) {
    $('.dropdown--offer .category_thumbnail').removeClass('active');
    const image = $(this).data('imageUrl');
    const thumbnailContainer = $(this).parent().parent().parent().next('.img').children('.category_thumbnail');
    thumbnailContainer.css('background-image', `url(${image})`)
});
dropdown.mouseout(function () {
    $('.dropdown--offer .category_thumbnail').css('background-image', `url(/images/menu-offer-bg.png)`).css('opacity', 1);
});

$('.owl-carousel').owlCarousel({
    stagePadding: 0,
    center: true,
    loop: true,
    margin: 10,
    nav: true,
    autoWidth: true,
    autoplay: false,
    navContainer: '.slider-nav',
    navText: ['<div class="arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></div>', '<div class="arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'],
    onInitialized: startTimer,
    onTranslated: startTimer,
})
var timer;

function startTimer(event) {
    clearInterval(timer);
    let timerBar = '<div class="slider-timer-bar"></div>';
    $('.owl-carousel .owl-item').find('.slider-timer-bar').remove();
    $('.owl-carousel .owl-item.center .item').append(timerBar);
    var activeItem = $('.owl-carousel .owl-item.center .item').find('.slider-timer-bar');
    var i = 0;

    timer = setInterval(function () {
        activeItem.css('width', (i) + '%');
        i += 1;
        if (i > 103) {
            clearInterval(timer);
            $('.owl-carousel').trigger('next.owl.carousel');
            i = 0;
        }
    }, 100);
}

$('#hamburger-menu').click(function () {
    var menu = $('.hamburger-menu-wrapper');
    if (menu.hasClass('active')) {
        $('body').removeClass('modal-open');
        menu.removeClass('active');
    } else {
        $('body').addClass('modal-open');
        menu.addClass('active');
    }
});
$('.hamburger-menu-wrapper .close').click(function () {
    $('.hamburger-menu-wrapper').removeClass('active');
});
